import type { NextPage } from 'next'
import Head from 'next/head'
import type { ReactNode } from 'react'
import React from 'react'

import Background from '../Background'
import { Header } from './Header'

type Props = {
  title?: string
  children: ReactNode
}

const Layout: NextPage<Props> = ({ children, title }) => {
  return (
    <>
      {title && (
        <Head>
          <title>{title}</title>
        </Head>
      )}
      <Header />
      <Background>{children}</Background>
    </>
  )
}

export default Layout
