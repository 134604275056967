import type { NextPage } from 'next'
import React from 'react'

import Layout from '~/components/Layout'
import NotFound from '~/components/NotFound'

const Page: NextPage = () => {
  return (
    <Layout title="404 | Ronin Account">
      <NotFound />
    </Layout>
  )
}

export default Page
