import clsx from 'clsx'
import Link from 'next/link'
import type { FC } from 'react'
import React from 'react'

import RoninLogoWithText from '~/public/images/ronin-logo-with-text.svg?svgr'

export const Header: FC<{ className?: string }> = ({ className = '' }) => {
  return (
    <header
      className={clsx(
        'flex items-center justify-center h-[72px] matcha-divider-bottom',
        className,
      )}
    >
      <Link href="/">
        <RoninLogoWithText className="h-24" />
      </Link>
    </header>
  )
}
